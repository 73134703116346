<template>
  <div>
    <div>
      <div class="_BoxHeader">
        <span>涉及范畴</span>
      </div>
      <div class="PageContain">
        <div class="_SelectHeader">
          <span class="_Title">列表信息：</span>
          <!-- <div class="_Select">
            <span>岗位：</span>
            <el-select v-model="value" placeholder="请选择">
              <el-option
                v-for="item in options"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              >
              </el-option>
            </el-select>
          </div> -->
          <div class="_Inp">
            <span>范畴名称：</span>
            <el-input
              placeholder="请输入文件名称"
              v-model="fileNameInp"
              @input="changInpVal"
              @clear="getdate"
              clearable
            />
          </div>
          <el-button type="success" icon="el-icon-search" @click="searchList"
            >搜索</el-button
          >
          <el-button type="primary" @click="dialogVisibleC = true"
            >添加下级范畴</el-button
          >
          <span style="margin-top: 2px;margin-left:10px">
            <img
              style="cursor: pointer;style:width:30px;height:30px"
              @click="delItem"
              src="../../assets/images/Examine_img/del.png"
              alt=""
            />
          </span>
        </div>
        <div style="display: flex">
          <div class="one_Box">
            <div class="Box_top">新增</div>
            <div></div>
            <div class="item" v-if="toadd">
              <span style="white-space: nowrap"> &nbsp; 编号：</span>
              <input
                style="width: 60%"
                type="text"
                v-model="oneItem.auditTypeNo"
              />
            </div>
            <div class="item" v-if="toadd">
              <span style="white-space: nowrap"> &nbsp; 名称：</span>
              <input
                type="text "
                style="width: 52%"
                v-model="oneItem.auditTypeName"
              />

              <i
                @click="toAddItem"
                style="transform: scale(1.5)"
                class="el-icon-circle-check"
              ></i>
            </div>
            <div class="additem" @click="toadd = true">
              <i class="el-icon-plus"></i>新增一级范畴
            </div>
          </div>
          <div class="two_Box">
            <div class="Box_top">范畴</div>
            <div style="overflow: hidden; overflow-y: auto; height: 530px">
              <el-tree
                :data="Ctdata"
                show-checkbox
                node-key="id"
                ref="tree"
                highlight-current
                :props="defaultProps"
                @node-click="handleNodeClick"
                @node-drag-end="handleDragEnd"
              >
              </el-tree>
            </div>
          </div>
          <div class="two_Box">
            <div class="Box_top">修改</div>
            <el-form style="margin-left: 20%" ref="form" label-width="80px">
              <el-form-item label="范畴名称">
                <el-input
                  style="width: 60%"
                  v-model="EItem.auditTypeName"
                ></el-input>
              </el-form-item>
              <el-form-item label="范畴编号">
                <el-input
                  style="width: 60%"
                  v-model="EItem.auditTypeNo"
                ></el-input>
              </el-form-item>
              <el-form-item label="上级范畴">
                <el-select
                  style="width: 60%"
                  filterable
                  v-model="EItem.upId"
                  placeholder="请选择"
                >
                  <el-option
                    v-for="item in options"
                    :key="item.value"
                    :label="item.auditTypeName"
                    :value="item.id"
                  >
                  </el-option>
                </el-select>
              </el-form-item>
              <el-form-item>
                <el-button type="primary" @click="onSubmit">修改</el-button>
                <el-button @click="cancel">取消</el-button>
              </el-form-item>
            </el-form>
          </div>
        </div>
      </div>
    </div>
    <el-dialog
      title="添加下级范畴"
      :visible.sync="dialogVisibleC"
      width="25%"
      :before-close="handleClose"
    >
      <el-form ref="form" label-width="80px">
        <el-form-item label="范畴名称">
          <el-input v-model="oneItem.auditTypeName"></el-input>
        </el-form-item>
        <el-form-item label="范畴编号">
          <el-input v-model="oneItem.auditTypeNo"></el-input>
        </el-form-item>
        <el-form-item label="上级范畴">
          <el-select
            style="width: 100%"
            filterable
            v-model="oneItem.upId"
            placeholder="请选择"
          >
            <el-option
              v-for="item in options"
              :key="item.value"
              :label="item.auditTypeName"
              :value="item.id"
            >
            </el-option>
          </el-select>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button type="primary" @click="toAddItem">确定添加</el-button>
        <el-button @click="dialogVisibleC = false">取 消</el-button>
      </span>
    </el-dialog>
    <Dialog> </Dialog>
  </div>
</template>

<script>
import { deleteMethod, get, post, put } from '../../api/http'
import { exportExcel } from '../../modules/ExportExcel'
import Dialog from '../../components/dialog/Dialog.vue'
export default {
  data() {
    return {
      myHeaders: { 'Access-Control-Allow-Origin': '*' },
      showDialog: false,
      fileNameInp: '',
      imageUrl: [],
      isAdd: true,
      showBtnText: false,
      rowItem: {},
      tableRowIndex: null,
      toadd: false,
      importDataList: [],
      options: [],
      dialogVisibleC: false,
      // 搜索
      fileNoInp: '',
      questionList: [],
      isSearch: false,
      // 参数
      fileNo: '',
      fileName: '',
      question: '',
      answer: '',
      imageAddr: [],
      lawAsk: '',
      // 分页
      currentPage: 1,
      pageSize: 20,
      //
      auditTypeName: '',
      areaName: '',
      postName: '',
      option1: [],
      option2: [],
      option3: [],
      curName: '',
      curID: null,
      Ctdata: '',
      oneItem: {
        id: 0,
        auditTypeNo: '',
        auditTypeName: '',
        upId: '',
        isDelete: 0,
        saveUserId: JSON.parse(sessionStorage.getItem('userInfo')).id + '',
      },
      EItem: {
        id: 0,
        auditTypeNo: '',
        auditTypeName: '',
        upId: '',
        isDelete: 0,
        saveUserId: JSON.parse(sessionStorage.getItem('userInfo')).id + '',
      },
      defaultProps: {
        label: 'auditTypeName',
        children: 'auditTypelist',
      },
    }
  },
  mounted() {
    this.getQuestionList()
    this.getdate()
    console.log(sessionStorage.getItem('userInfo').id)
    this.imFile = document.getElementById('imFile')
  },
  methods: {
    handleSizeChange(val) {
      this.currentPage = 1
      this.pageSize = val
    },
    handleCurrentChange(val) {
      this.currentPage = val
    },
    rowClick(row) {
      console.log(row)
      this.rowItem = row
      this.tableRowIndex = row.index
      this.questionList.map((item) => {
        item.checked = false
        if (row.id == item.id) {
          row.checked = true
        }
      })
    },
    tableRowClassName({ row, rowIndex }) {
      row.index = rowIndex
    },
    getdate() {
      get('/api/AuditType/GetTree').then((res) => {
        this.Ctdata = res.data
      })
      get('/api/AuditType/GetList').then((res) => {
        this.options = res.data
      })
    },
    selectedstyle({ rowIndex }) {
      if (this.tableRowIndex == rowIndex) {
        return {
          'background-color': '#E2E8FA !important',
          'font-weight': 'bold !important',
          color: '#555555',
        }
      }
    },
    changInpVal() {
      this.isSearch = false
      if (this.fileNoInp == '' && this.fileNameInp == '') this.getQuestionList()
    },
    handleClose() {
      this.showDialog = false
      this.dialogVisibleC = false
      this.imageUrl = []
    },
    searchList() {
      if (this.fileNameInp == '') return
      get(
        '/api/AuditType/GetByAuditType?AuditTypeName=' + this.fileNameInp
      ).then((res) => {
        this.Ctdata = res.data
      })
    },
    getQuestionList() {
      var url = this.isSearch
        ? '/api/QuestionPool/GetByFile?FileNo=' +
          this.fileNoInp +
          '&FileName=' +
          this.fileNameInp
        : '/api/NewQuestionPool/GetList'
      get(url).then((resp) => {
        if (resp.code == 200) {
          console.log()
          this.questionList = resp.data
          this.rowItem = {}
          this.tableRowIndex = null
        }
      })
    },
    handleAvatarSuccess(res, file) {
      this.imageUrl.push(URL.createObjectURL(file.raw))
    },
    getfile(file) {
      if (file.response == undefined) return
      if (this.imageAddr.length == 0) {
        this.imageAddr = file.response.value //图片上传到服务器，请求后返回的图片路径
      } else {
        this.imageAddr = this.imageAddr + ',' + file.response.value
      }
    },
    // 增删改
    handleReqData() {
      var data = {
        id: this.isAdd ? 0 : this.rowItem.id,
        fileNo: this.fileNo,
        fileName: this.fileName,
        question: this.question,
        answer: this.answer,
        imageAddr: this.imageAddr,
        lawAsk: this.lawAsk,
        isDelete: 0,
        questionAndArea: this.areaName.toString(),
        questionAndFunction: this.postName.toString(),
        questionAndAuditType: this.auditTypeName.toString(),
        saveTime: this.isAdd ? new Date().toISOString() : this.rowItem.saveTime,
        saveUserId: JSON.parse(sessionStorage.getItem('userInfo')).id + '',
      }
      if (data.imageAddr.length === 0) data.imageAddr = ''
      var methods = this.isAdd ? post : put
      methods('/api/NewQuestionPool', data)
        .then((resp) => {
          if (resp.code == 200) {
            this.$message({
              type: 'success',
              message: this.isAdd ? '添加成功' : '修改成功',
            })
            this.showDialog = false
            this.isSearch = false
            this.getQuestionList()
          } else {
            this.$message.error(resp.message)
          }
        })
        .catch(() => {
          this.$message.error(this.isAdd ? '添加失败' : '修改失败')
        })
    },
    addQuestion() {
      this.showDialog = true
      this.isAdd = true
      this.showBtnText = false
      this.fileNo = ''
      this.fileName = ''
      this.question = ''
      this.answer = ''
      this.imageAddr = []
      this.lawAsk = ''
      this.areaName = ''
      this.postName = ''
      this.auditTypeName = ''
    },
    editItem() {
      if (this.tableRowIndex == null) return
      this.imageUrl = []
      this.isAdd = false
      this.showBtnText = true
      this.showDialog = true
      this.answer = this.rowItem.answer
      this.fileNo = this.rowItem.fileNo
      this.fileName = this.rowItem.fileName
      this.question = this.rowItem.question
      this.imageAddr = this.rowItem.imageAddr
      this.lawAsk = this.rowItem.lawAsk
      this.postName =
        this.rowItem.questionAndFunction.split(',')[0] > 0
          ? this.rowItem.questionAndFunction.split(',').map(Number)
          : ''
      this.auditTypeName =
        this.rowItem.questionAndAuditType.split(',')[0] > 0
          ? this.rowItem.questionAndAuditType.split(',').map(Number)
          : ''
      this.areaName =
        this.rowItem.questionAndArea.split(',')[0] > 0
          ? this.rowItem.questionAndArea.split(',').map(Number)
          : ''
      console.log(this.areaName)
      if (this.imageAddr.length == 0) return
      var imgList = this.imageAddr.split(',')
      imgList.map((item) => {
        this.imageUrl.push('http://192.168.2.125:6280/uploads/' + item)
      })
    },
    handleNodeClick(e) {
      e.upId = e.upId == 0?'':e.upId
      this.EItem = e
      this.curID = e.id
      this.curName = e.auditTypeName
    },
    toAddItem() {
      if (this.oneItem.upId === '') this.oneItem.upId = 0
      post('/api/AuditType', this.oneItem).then((res) => {
        this.$message({
          type: 'success',
          message: res.message,
        })
        console.log(res)
        this.oneItem.upId = 0
        this.oneItem.id = 0
        this.oneItem.auditTypeName = ''
        this.oneItem.auditTypeNo = ''
        this.dialogVisibleC = false
        this.getdate()
      })
    },
    onSubmit() {
      this.EItem.upId = this.EItem.upId == '' ? 0 : this.EItem.upId
      put('/api/AuditType', this.EItem).then((res) => {
        this.$message({
          type: 'success',
          message: res.message,
        })
        this.EItem.upId = ''
        this.EItem.id = ''
        this.EItem.auditTypeName = ''
        this.EItem.auditTypeNo = ''
        this.dialogVisibleC = false
        this.getdate()
      })
    },
    cancel(){
      this.EItem = {};
    },
    handleDragEnd(e, v) {
      console.log(e.data, v.data)
      e.data.upId = v.data.id
      if (e === v) return
      put('/api/AuditType', e.data).then((res) => {
        this.$message({
          type: 'success',
          message: res.message,
        })
      })
    },
    delItem() {
      if (this.curID == null) return
      this.$confirm('您确认要删除“' + this.curName + '”这个范畴？')
        .then(() => {
          deleteMethod('/api/AuditType?Id=' + this.curID)
            .then((resp) => {
              if (resp.code == 200) {
                this.$message({
                  type: 'success',
                  message: '删除成功',
                })
                this.getdate()
              } else {
                this.$message.error(resp.message)
              }
            })
            .catch(() => {
              this.$message.error('删除失败')
            })
        })
        .catch(() => {})
    },
    // 导入excel
    uploadFile: function () {
      // 点击导入按钮
      this.imFile.click()
    },
    importQuestion(list) {
      post('/api/QuestionPool/BatchAdd', {
        data: JSON.stringify(list),
      })
        .then((resp) => {
          if (resp.code == 200) {
            this.$message({
              type: 'success',
              message: '导入成功',
            })
            this.getQuestionList()
          } else {
            this.$message.error(resp.message)
          }
        })
        .catch(() => {
          this.$message.error('导入失败')
        })
    },
    // 导出
    exportExcel() {
      return exportExcel('#tableData', '题库维护.xlsx')
    },
  },
  components: { Dialog },
}
</script>

<style lang="less" scoped>
.dialog ._Select {
  width: 100% !important;
}
.dialog .el-select {
  width: 100%;
  margin-top: 10px;
}
.dialog .contaion > div:nth-of-type(1) {
  display: flex;
}
.dialog .contaion > div:nth-of-type(1) div {
  padding-right: 30px;
}
.dialog ._Select > span,
.dialog ._Inp > span {
  width: 170px;
}
.select-header {
  width: 99%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-top: 1px solid #e9e7e8;
  padding-top: 10px;
  > div {
    display: flex;
    align-items: center;
    margin-right: 20px;
  }
}
/* 上传图片 */
.avatar-uploader {
  margin-left: 38px;
  padding-right: 30px;
  padding-top: 20px;
}
/deep/ .avatar-uploader .el-upload {
  border-radius: 6px;
  cursor: pointer;
  position: relative;
  overflow: hidden;
  display: flex;
  flex-wrap: wrap;
}
.avatar-uploader .el-upload:hover {
  border-color: #409eff;
}
.avatar-uploader-icon {
  font-size: 28px;
  color: #b4b3b3;
  width: 80px;
  height: 80px;
  line-height: 80px;
  text-align: center;
  border: 1px dotted #b4b3b3;
  border-radius: 2px;
  margin-top: 4px;
}
.avatar {
  width: 80px;
  height: 80px;
  display: block;
  margin-right: 4px;
  margin-top: 4px;
}

._Inp1 .el-select {
  width: 75%;
}
._Inp .el-select {
  margin-top: 0;
  height: auto !important;
  display: flex !important;
}
._Inp .el-select > span {
  width: auto !important;
}
._Inp1 {
  margin-left: 11.3%;
  display: flex;
  align-items: center;
  justify-content: center;
}
._Inp1 input {
  display: flex;
  height: auto;
}
._Inp1 span {
  white-space: nowrap;
}
.one_Box {
  width: 12%;
  margin-left: 2%;
  margin-right: 1%;
}
.two_Box {
  width: 30%;

  margin-right: 1%;
  border: 1px solid #e3e3e3;
}
.Box_top {
  width: 100%;
  height: 40px;
  font-size: 14px;
  font-family: Microsoft YaHei;
  font-weight: bold;
  color: #ffffff;
  background: #5b79ae;
  display: flex;
  justify-content: center;
  align-items: center;
}
.additem {
  width: 100%;
  height: 40px;
  font-size: 16px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #5a8bff;
  border-radius: 1px dish;
  border: 1px dashed #5a8bff;
}
.item {
  width: 100%;
  height: 40px;
  font-size: 16px;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px solid #dadada;
}
input {
  width: 120px;
}
</style>
